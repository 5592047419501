import { Add, CallMade, Refresh, Star, StarOutline } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

export default function CiteCard(props) {

    const [cite, setSite] = useState("IEEE");
    const [loading, setLoading] = useState(false);
    let user = JSON.parse(localStorage.getItem("user"));

    const citeInEditor = async () => {
        setLoading(true);
        const payload = {
            text: props.data.citationStyles.bibtex,
            userid: user.id,
            format: cite,
        };
        try {
            const res = await axios.post(
                "https://api.heygyani.com/generate_citations/",
                payload
            );
            props.handleCitations(res.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        props.setSideMenu(false);
    };

    return (
        <div className="flex flex-col gap-2 justify-between items-start p-3 border-2 shadow-lg my-2 rounded-lg border-gray-200 !z-1000">
            <div>
                <p className="text-sm text-left line-clamp-2">{props.data.title}</p>
            </div>
            <div>
                <p className="text-xs text-left text-gray-500">
                    {props.data.authors.map((author, index) => (
                        <span className="text-blue-500">
                            {author?.name}
                            {index !== props.data.authors.length - 1 && ", "}
                        </span>
                    ))}
                    <br />
                    <span className="italic">{props.data.journal?.name},{" "}
                        {props.data.journal?.volume &&
                            `Volume: ${props.data.journal?.volume}, `}
                    </span>
                </p>
            </div>
            <div className="flex justify-between w-full text-xs">
                <div className="flex flex-row items-center gap-2">
                    <button onClick={() => citeInEditor()} className="text-indigo-600 text-xs flex justify-center items-center"><Add /> <span>Cite {props.data.citationCount} <>{loading && <Refresh className="!animate-spin" />}</></span></button>
                    <button onClick={props.onViewInNewTab} className="text-gray-600 text-xs flex justify-center items-center"><CallMade /><span>View</span></button>
                </div>
                <div className="flex flex-row items-center gap-2 ">
                    {/* Select CITATION Type */}
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={cite}
                        onChange={(e) =>
                            setSite(e.target.value)
                        }
                        label="Age"
                        className="h-[28px]"
                    >
                        <MenuItem value={"IEEE"}>IEEE</MenuItem>
                        <MenuItem value={"Chicago"}>Chicago</MenuItem>
                        <MenuItem value={"Harvard"}>Harvard</MenuItem>
                        <MenuItem value={"MLA"}>MLA</MenuItem>
                        <MenuItem value={"APA"}>APA</MenuItem>
                    </Select>
                    <button onClick={() => props.setBookmark}>
                        {
                            props.isBookmarked ?
                                <Star className="text-yellow-500" />
                                :
                                <StarOutline className="text-yellow-500" />
                        }
                    </button>
                </div>
            </div>
        </div>
    );
}