import React from "react";
import BookDescription2 from "../Reusable/BookDescription2";
import { BookReusable4 } from "../Reusable/BookReusable4";

function Reading4() {
  return (
    <div
      style={{
        marginTop: "108px",
        marginLeft: "46px",
        height: "auto",
        maxWidth: "100%",
      }}
    >
      <div style={{ position: "relative", height: "511px", marginTop: "48px" }}>
        <BookDescription2 />
      </div>
      <div style={{ width: "100%" }}>
        <BookReusable4 />
      </div>
    </div>
  );
}

export default Reading4;
