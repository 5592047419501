import React, { useState } from "react";
import { Box, CssBaseline, IconButton } from "@mui/material";
import Sidebar from "./components/Sidebar"; // Assuming Sidebar is your sidebar component
import MenuIcon from "@mui/icons-material/Menu"; // Icon for sidebar toggle
import { Routes, Route } from "react-router-dom";
import HomeLanding from "./components/books_landing/HomeLanding";
import MyNotes from "./components/books_landing/MyNotes";
import Bookmark1 from "./components/books_landing/Bookmark1";
import AIBot from "./components/SummaryGenerator/AIBot";
import Settings from "./components/Settings";
import Profile from "./components/user-profile/Profile";
import Subscriptions from "./components/Subsriptions/Subscriptions";
import NotFound from "./components/NotFound";

function App() {
  // State to track if sidebar is open
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <CssBaseline />

      {/* Sidebar - Fixed position */}
      {/* {isSidebarOpen && ( */}
        <Box sx={{ position: "fixed", height: "100vh", zIndex: 200 }}>
          <Sidebar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen}/>
        </Box>
      {/* )} */}

      {/* Main Content - Scrollable */}
      <Box
        sx={{
          flexGrow: 1,
          ml: isSidebarOpen ? "230px" : "50px", // Adjust margin based on sidebar state
          p: 2,
          overflowY: "auto",
          height: "100vh",
          transition: "margin 0.3s ease", // Smooth transition when sidebar toggles
        }}
      >

        {/* Routes */}
        <Routes>
          <Route path="/home" element={<HomeLanding />} />
          <Route path="/mynotes" element={<MyNotes />} />
          <Route path="/bookmarks" element={<Bookmark1 />} />
          <Route path="/chatbot" element={<AIBot />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
