import React, { useState } from "react";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, Divider } from "@mui/material";
import { Home, NotesOutlined, BookmarkBorderOutlined, ChatBubbleOutline, SettingsOutlined, AccountCircle, Payment, Menu, ChevronLeft } from "@mui/icons-material";
import gyani from "../SVGAvatars/fullLogo.svg";
import { Link, useLocation } from "react-router-dom";

const Sidebar = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    props.setSidebarOpen(!props.isSidebarOpen);
  };

  const navItems = [
    { text: "Home", icon: <Home />, path: "/dashboard/home" },
    { text: "My Notes", icon: <NotesOutlined />, path: "/dashboard/mynotes" },
    { text: "Bookmarks", icon: <BookmarkBorderOutlined />, path: "/dashboard/bookmarks" },
    { text: "Chat Bot", icon: <ChatBubbleOutline />, path: "/dashboard/chatbot" },
    { text: "Profile", icon: <AccountCircle />, path: "/dashboard/profile" },
    { text: "Subscriptions", icon: <Payment />, path: "/dashboard/subscriptions" },
  ];

  return (
    <Box
      sx={{
        width: isOpen ? 240 : 60,
        transition: "width 0.3s",
        backgroundColor: "#EFEFFD",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className={`flex justify-between items-center ${isOpen && 'mx-4'}`}>
        {isOpen && <img src={gyani} className="h-10 w-24" alt="Logo Error" />}
        <IconButton onClick={toggleSidebar} sx={{ margin: 1 }}>
          {isOpen ? <ChevronLeft /> : <Menu />}
        </IconButton>
      </div>

      {/* Navigation Links */}
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              // Apply active style based on the current route
              sx={{
                backgroundColor: location.pathname === item.path ? "#D1D5DB" : "inherit",
                color: location.pathname === item.path ? "#000" : "inherit",
                "&:hover": {
                  backgroundColor: "#D1D5DB",
                },
              }}
            >
              <ListItemIcon
                sx={{ color: location.pathname === item.path ? "#4A4A68" : "inherit" }}
              >
                {item.icon}
              </ListItemIcon>
              {isOpen && <ListItemText primary={item.text} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider />

      {/* Additional Items (Settings) */}
      <List sx={{ mb: 2 }}>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/dashboard/settings"
            sx={{
              backgroundColor: location.pathname === "/dashboard/settings" ? "#D1D5DB" : "inherit",
              color: location.pathname === "/dashboard/settings" ? "#000" : "inherit",
              "&:hover": {
                backgroundColor: "#D1D5DB",
              },
            }}
          >
            <ListItemIcon
              sx={{ color: location.pathname === "/dashboard/settings" ? "#4A4A68" : "inherit" }}
            >
              <SettingsOutlined />
            </ListItemIcon>
            {isOpen && <ListItemText primary="Settings" />}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default Sidebar;