import {
  AutoAwesome,
  Send,
  Sync,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Fade } from "react-reveal";
import "./index.css";
import Chatbot_logo from "../../../src/SVGAvatars/Chatbot_logo.svg";
import { ActionIcon, rem, TextInput } from "@mantine/core";

function AIBot(props) {
  let user = JSON.parse(localStorage.getItem("user"));

  const [displayMessages, setdisplayMessages] = useState([]);

  const bottomRef = useRef(null);

  useEffect(() => {
    if(props.type === null || props.type === undefined){
      let msgs = JSON.parse(localStorage.getItem("chats"));
    if (msgs !== null) setdisplayMessages(msgs);
    }
  }, []);

  useEffect(() => {
    bottomScroll();
  }, [displayMessages]);

  const [msg, setMsg] = useState("");
  const [input, setInput] = useState(false);

  function bottomScroll() {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  async function getChat() {
    if (msg.length <= 0) {
      alert("Please Write Message !");
      return;
    }

    setInput(true);

    // Add the user's message to the state
    setdisplayMessages((prevMessages) => [
      ...prevMessages,
      [msg, "sent"],
    ]);

    setMsg("");

    try {
      const res = await axios.post(
        "https://api.heygyani.com/ind_Elaborate/",
        {
          text: msg+" this Also format the response with boldness in text if they are headings or subheadings and ignore if they are normal text ",
          lang: "English",
          userid: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Add the received message to the state
      setdisplayMessages((prevMessages) => [
        ...prevMessages,
        [res.data, "rcvd"],
      ]);
    } catch (error) {
      console.error("Error fetching chat response:", error);
    } finally {
      setInput(false);
    }
  }

  function saveChats() {
    localStorage.setItem("chats", JSON.stringify(displayMessages));
    console.log("Chats Saved Locally ");
  }

  // Function to format and style message content
function formatMessage(message) {
  // Split the message by line breaks to handle multi-line messages
  const lines = message.split('\n');

  return lines.map((line, index) => {
    // Check for headings (e.g., Markdown-style `## Heading`)
    if (line.startsWith('## ')) {
      return (
        <h2 key={index} className="text-xl font-bold mb-2">
          {line.replace('## ', '')}
        </h2>
      );
    }
    // Check for subheadings (e.g., `### Subheading`)
    else if (line.startsWith('### ')) {
      return (
        <h3 key={index} className="text-lg font-semibold mb-1">
          {line.replace('### ', '')}
        </h3>
      );
    }
    // Check for bold text (e.g., `**bold text**`)
    else if (line.includes('**')) {
      return (
        <p key={index} className="mb-2">
          {line.split('**').map((part, idx) =>
            idx % 2 === 1 ? <strong key={idx}>{part}</strong> : part
          )}
        </p>
      );
    }
    // Default to normal paragraph text
    else {
      return (
        <p key={index} className="mb-2">
          {line}
        </p>
      );
    }
  });
}


  return (
    <div className="flex flex-col justify-between w-full">
      {/* Top Bar */}
      <div className={`${props.type === 'chatpdf' ? 'hidden' : 'fixed top-0 z-10 w-full flex justify-between border-b-2 border-gray-200 p-1 bg-white mx-2'}`}>
        <div className="flex justify-between items-center">
          <img
            src={Chatbot_logo}
            alt="Chatbot"
            className="w-10 sm:w-16 h-6 sm:h-16 rounded-full"
          />
          <div>
            <div className="text-2xl text-gray-700">Gyani Bot</div>
            <div className="text-md text-gray-600">Intelligent AI</div>
          </div>
        </div>
        <Tooltip title="Save Chats" arrow>
          <IconButton
            aria-label="Save Chats"
            onClick={() => saveChats()}
            className="hover:opacity-75"
          >
            <Sync />
          </IconButton>
        </Tooltip>
      </div>
      
      {/* Chat View - Scrollable */}
      <div
        id="messages"
        className="mb-10 flex-grow p-3 overflow-y-scroll scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2"
        style={{ fontFamily: "Work Sans" }}
      >
        {displayMessages.map((msg, i) => (
          <Fade bottom key={i}>
            <div className="chat-message py-2 w-full">
              <div className={`flex w-full items-end ${msg[1] === "sent" ? "justify-end" : ""}`}>
                <div className="max-w-3xl mx-2">
                  <span className={`px-4 py-2 ${props.type !== undefined && 'text-sm'} text-left rounded-lg inline-block rounded-bl-none ${msg[1] === "sent" ? "bg-[#428CFB] text-white" : "bg-gray-300 text-gray-600"} whitespace-pre-wrap`}>
                    {formatMessage(msg[0])}
                  </span>
                </div>
              </div>
            </div>
          </Fade>
        ))}
        <div id="bottomRef" className="my-3" ref={bottomRef} />
      </div>

      {/* Input Box */}
      <div className={`${props.type === 'chatpdf' && 'fixed bottom-0'} fixed bottom-0 w-[95%] flex items-center p-3 bg-white border-t-2 border-gray-200`}>
        <TextInput
          radius="xl"
          size={props.type !== undefined ? 'md' : 'xl'}
          className="flex-grow text-sm"
          value={msg}
          placeholder="Enter chat..."
          onChange={(e) => setMsg(e.target.value)}
          onKeyDown={(e) => {
            if (e.shiftKey && e.code === 13) setMsg(msg + "\n");
            else if (e.key === "Enter" && !input && !e.shiftKey) getChat();
          }}
          leftSection={<AutoAwesome style={{ color: "blue", width: rem(20), height: rem(20) }} stroke={2.5} />}
          rightSection={
            <ActionIcon size={props.type !== undefined ? 30 : 50} radius="xl" variant="filled" onClick={() => getChat()}>
              <Send style={{ width: rem(20), height: rem(20) }} stroke={2.5} />
            </ActionIcon>
          }
        />
      </div>
    </div>
  );
}

export default AIBot;
