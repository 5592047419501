import Search from "@mui/icons-material/Search";
import React from "react";

function WIkiCard(props) {
  return (
    <div className="w-[90%] border-2 hover:border-grey-500 flex flex-col justify-start items-start my-5 p-3 rounded-lg">
      {/* Bookmark Button
      <div className="flex items-center justify-between w-full">
        <button
          className="h-[42px] flex flex-row justify-center items-center px-4 gap-2 rounded-[8px] hover:bg-blue-700 text-[16px] font-[700] leading-[19px] text-[#c7c7c7] outline-none focus:outline-4 focus:outline-[#31D0AA] ml-auto"
        >
          <BookmarkIcon color="light" />
        </button>
      </div> */}

      {/* Title */}
      <div className="font-bold text-lg mb-2">{props.data.title}</div>

      {/* Source and Year */}
      <div className="text-gray-500 mb-2">{props.data.timestamp} </div>

      {/* Abstract */}
      <div className="border p-2 rounded-md border-gray-300 mb-3">
        {props.data.data ? (
          <div className="text-gray-500  ">{props.data.data}</div>
        ) : (
          <div className="text-gray-500 text-ellipsis line-clamp-4">
            No result Found
          </div>
        )}
      </div>
      {/* Explore Button  */}
      {/* <button
        className="border-[1px] border-blue-500 rounded-xl py-1 px-3 text-black"
        onClick={() => window.open(props.data.content_urls.desktop, "_blank")}
        >
         Explore <Search/>
      </button>
       */}
    </div>
  );
}

export default WIkiCard;
