import React from "react";
import { InfinitySpin } from "react-loader-spinner";

function Loading() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
      }}
    >
      <InfinitySpin
        visible={true}
        width={200}
        color="#42C0FB"
        ariaLabel="infinity-spin-loading"
      />
    </div>
  );
}

export default Loading;
