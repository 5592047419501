import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { ActionIcon, Button, MantineProvider, rem, TextInput } from "@mantine/core";
import "@mantine/core/styles.css";
import classes from "./CiteMenu.module.css";
import Loading from "../../books_landing/Loading";
import ResearchCard from "../ResearchCard";
import { Close, KeyboardArrowRightOutlined } from "@mui/icons-material";
import CiteCard from "./CiteCard";

export function CiteMenu(props) {
  const handleView = (url) => {
    window.open(url, "_blank");
  };
  const inlineMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inlineMenuRef.current &&
        !inlineMenuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".mantine-Menu-dropdown")
      ) {
        props.setCiteMenu(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  // useEffect(() => {
  //   props.research(props.generateInput);
  // }, [props.generateInput]);
  // const setBookmark = async (id, title, paperId, url) => {
  //   try {
  //     //   const isAlreadyBookmarked = ;

  //     // if (isAlreadyBookmarked) {
  //     //   console.log("Paper is already bookmarked.");
  //     //   setisBookmarked(true);
  //     //   return;
  //     // }
  //     let data = JSON.stringify({
  //       title: title,
  //       userid: id,
  //       url: url,
  //       paper_id: paperId,
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: "https://api.heygyani.com/paper_bookmark/",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     const response = await axios.request(config);
  //     setisBookmarked(true);
  //     console.log(JSON.stringify(response.data));
  //   } catch (error) {
  //     setisBookmarked(false);
  //     if (error.response) {
  //       console.error("Server responded with an error:", error.response.data);
  //       console.error("Status code:", error.response.status);
  //     } else if (error.request) {
  //       console.error("No response received from the server");
  //     } else {
  //       console.error("Error setting up the request:", error.message);
  //     }
  //   }
  // };
  const [researchResponse, setResearchResponse] = useState<any>([]);
  const research = async (loadMore = false) => {
    props.setLoading(true);

    const newOffset = loadMore ? props.offset + 10 : 10;
    const payload = {
      keyword: props.generateInput,
      limit: 10,
      offset: parseInt(newOffset),
    };

    console.log(payload)
    try {
      const res = await axios.post(
        "https://api.heygyani.com/search_publication/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.error === undefined) {
        props.setOffset(newOffset);
        setResearchResponse((prevResults) =>
          loadMore ? [...prevResults, ...res.data.data] : res.data.data
        );

        if (!loadMore && props.academic) {
          props.setShowLoadMore(true);
        }
      }
      if (res.data.error.length > 0) {
        throw new Error(res.data.error.toString());
      }
    } catch (error) {
      console.error("Error in research:", error);
    } finally {
      props.setLoading(false);
    }
  };


  return (
    <div ref={inlineMenuRef}>
      {/* <div className="flex justify-between "> */}
      <div className="relative p-0" style={{
        position: "absolute", // Use absolute positioning
        top: `${props.mouseY - 25}px`, // Position using mouse coordinates
        left: `${props.mouseX - 300}px`,
        zIndex: 500, // Ensure it appears above other content
      }} >
        <TextInput
          className=" py-0"
          radius="sm"
          size="lg"
          placeholder="Ask Gyani AI anything"
          classNames={{ input: classes.input }}
          defaultValue={props.generateInput}
          onChange={props.setPropGenerateInput}
          rightSectionWidth={42}
          rightSection={
            <ActionIcon
              onClick={() => {
                research(true);
              }} size={32} radius="xl" variant="filled">
              <KeyboardArrowRightOutlined />
            </ActionIcon>
          }
        />
        {/* </div> */}
        <div className="w-full p-0 text-[0.8rem]">
          {!props.showLoadMore && props.loading && <Loading />}
          {researchResponse?.length >= 1 &&
            researchResponse.map(card=>(
              <div className="z-150 mb-8 p-0" key={card.paperId}>
                    <CiteCard
                      saveText={props.saveText}
                      getIndex={props.getIndex}
                      caption={props.caption}
                      headCaption={props.headCaption}
                      titleObject={props.titleObject}
                      notes={props.notes}
                      isBookmarked={props.isBookmarked}
                      setLoading={props.setLoading}
                      data={card}
                      setSideMenu={props.setSideMenu}
                      editor={props.ref}
                      onViewInNewTab={() => handleView(card.url)}
                    // onsetBookmark={() =>
                    //   setBookmark(user.id, card.title, card.paperId, card.url)
                    // }
                    />
                  </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
