import {
  Add,
  Close,
  Delete,
  Done,
  Edit,
  Grid4x4Outlined,
  ListAlt,
  Notes,
  Science,
  Search,
  Share,
  Stop,
  Save,
  Clear,
  Timer,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Menu,
} from "@mui/material";
// import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LeftMenuButtonSVG from "../SummaryGenerator/images/icons/toggle-sidebar-open.svg";
import "./animation.css";

// import React, { useState } from 'react';
import NotesMaker from "../SummaryGenerator/NotesMaker.jsx";

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import Typewriter from "typewriter-effect";
import "../SummaryGenerator/NotesMaker.css";
import { database } from "../../Firebase.js";
import { ref, remove } from "firebase/database";
import { useLocation } from "react-router-dom";
import generatePDF, { Resolution, Margin as RTPMargin } from 'react-to-pdf';

function MyNotes(props) {
  const { state } = useLocation();
  let user = JSON.parse(localStorage.getItem("user"));
  let [notes, updateNotes] = useState([]);
  const [grid, setGrid] = useState(false);
  const [changeTitle, setchangeTitle] = useState(-1);
  const [newTitle, setNewTitle] = useState();
  const [expand1, setExpand1] = useState(-1);
  const [search, setSearch] = useState("");
  const [sortOrder, setsortOrder] = useState("nosort");
  const [newNotes, setNewNotes] = useState(false);
  const [newNotesTitle, setNewNotesTitle] = useState("");
  const [showError, setshowError] = useState("");
  const [savedNotes, setSavedNotes] = useState(null);
  const [notesName, setNotesName] = useState("");
  const [category, setCategory] = useState(
    Object.keys(notes).length > 0 ? Object.keys(notes)[0] : ""
  );
  const [stopType, setStopType] = useState(false);
  const [viewNotes, toggleNotes] = useState(false);
  const [editNotes, setEditNotes] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [editRequest, setEditRequest] = useState("POST");
  const [sideMenu, setSideMenu] = useState(false);
  const [timerStart, setTimerStart] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [text, setText] = useState(null);
  const [time, setTime] = useState(parseInt(localStorage.getItem("notesTime")));
  const [editView, setEditView] = useState(true);
  const [editCatTitle, setEditCatTitle] = useState(false);
  const [categoryDialog, setCategoryDialog] = useState(false);
  const [shareDialog, setShareDialog] = useState([]);
  const [shareURL, setShareURL] = useState([]);
  const [sharedalert, setSharedalert] = useState("");
  const [access, setAccess] = useState("view");
  const [accessMail, setAccessMail] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [catSwitch, setCatSwitch] = useState(false);
  const [notesId, setNotesId] = useState();
  const [Notesuuid, setNotesuuid] = useState();
  const [importalert, setimportalert] = useState(false);
  const [timerd, settimerd] = useState("false");
  const [plusd, setplusd] = useState("false");
  const [new1, setnew1] = useState("false");
  const [paged, setpaged] = useState("false");
  const [completiond, setcompletiond] = useState("false");
  const [re, setre] = useState("false");
  const [gs, setgs] = useState("false");
  const [sav, setsav] = useState("false");
  const [sar, setsar] = useState("false");
  const [re2, setre2] = useState("false");
  const [parad, setparad] = useState("false");
  const [dri, setdri] = useState("false");
  const [caption, setCaption] = useState("");
  const [headCaption, setHeadCaption] = useState("");
  const [isMobileView, setIsMobileView] = useState(false);
  const [DanchorEl, setAnchorEl] = useState(null);
  const [localfile, setLocalfile] = useState();
  const [googlefile, setGooglefile] = useState();

  const DhandleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const DhandleCloseMenu = () => {
    setAnchorEl(null);
  };
  const editorRef = useRef(null);
  const receiveRefFromChild = (ref) => {
    // Assign the received ref to the editorRef in the parent component
    editorRef.current = ref.current;
  };

  const enterText = async (text) => {
    const lines = text.split("\n").filter((line) => line.trim() !== "");
    const lineArray = [];
    lines.forEach((line) => {
      const trimmedLine = line.trim();
      if (/^\d+(\.|\))/.test(trimmedLine)) {
        const finalLine = trimmedLine.replace(/^\d+(\.|\))/, "");
        if (finalLine !== "") {
          lineArray.push(finalLine);
          editorRef.current.blocks.insert("list", {
            style: "unordered",
            items: lineArray,
          });
          lineArray.pop();
        }
      } else {
        editorRef.current.blocks.insert("paragraph", {
          text: trimmedLine,
        });
      }
    });
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCategoryTitle = (category, title) => {
    setHeadCaption(category);
    setCaption(title);
  };
  const uploadLocalFileEditor = async () => {
    if (!localfile || !localfile.name.endsWith(".txt")) {
      alert("Please select a .txt file.");
      return;
    }

    try {
      const reader = new FileReader();

      reader.onload = () => {
        const text = reader.result;

        enterText(text);
        console.log("Text content:", text);
      };

      reader.readAsText(localfile);
      setimportalert(false);
    } catch (error) {
      alert("An error occurred while processing the file.");
      console.error(error);
    }
  };
  const uploadGoogleFileEditor = async (fileId) => {
    try {
      // Make API call to fetch file content from Google Drive
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer 2134 `, // Replace accessToken with your access token
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file from Google Drive");
      }

      const text = await response.text();

      enterText(text);
      console.log("Text content:", text);
    } catch (error) {
      alert("An error occurred while importing file from Google Drive.");
      console.error(error);
    }
  };
  const titleObject = useMemo(() => {
    return {};
  }, []);
  useEffect(() => {
    const preHead = Object.keys(notes);

    preHead.forEach((cat) => {
      const titles = notes[cat]?.map((item) => item.title) || [];
      titleObject[cat] = titles;
    });
    // console.log("Titles Structure:", titleObject);
  }, [notes, titleObject]);

  const [catOpen, setCatOpen] = useState(-1);
  const fetchNotes = useCallback(async () => {
    try {
      let res = await axios.get(
        `https://api.heygyani.com/library/notes/${user.personalisation.id}/`
      );

      const response = Object.keys(res.data).reduce((result, key) => {
        const updatedNotes = res.data[key].map((item) => {
          const { id, uuid, user, title, category, notes, date_created } = item;
          return { id, uuid, user, title, category, notes, date_created };
        });

        result[key] = updatedNotes;
        return result;
      }, {});
      // console.log("fetchNotes wala response", response);
      setCategory(Object.keys(response)[0]);
      updateNotes(response);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  }, [user.personalisation.id, updateNotes]);

  useEffect(() => {
    if (!(state === null)) {
      setCategoryDialog(true);
      setplusd(localStorage.getItem("first_time_user"));
    } else {
      setplusd(localStorage.getItem("first_time_user"));
    }

    fetchNotes();
  }, [fetchNotes, state]);

  async function handleDelete(id) {
    let res = await axios.get(
      `https://api.heygyani.com/library/notes/${user.personalisation.id}/${id}/delete/`
    );
    setshowError(res.status === 204 ? "Notes Deleted" : "");
    const deleteRef = ref(database, "/" + user.personalisation.id + "/" + id);

    remove(deleteRef).then(() => {
      console.log("location removed");
    });
    fetchNotes();
  }
  const vertical = "top";
  const horizontal = "right";

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const keys = Object.keys(notes);
    const [reorderedKey] = keys.splice(result.source.index, 1);
    const vals = Object.values(notes);
    const [reorderedVal] = vals.splice(result.source.index, 1);
    keys.splice(result.destination.index, 0, reorderedKey);
    vals.splice(result.destination.index, 0, reorderedVal);
    let obj = {};
    keys.forEach((k, i) => {
      obj[k] = vals[i];
    });
    updateNotes(obj);
  }

  async function updateTitle(uuid, newTitle) {
    console.log(newTitle);
    if (newTitle.trim().length <= 1) {
      setshowError("Please Add Title");
      setchangeTitle(-1);
    }

    // Else Update Title
    else {
      let payload = {
        title: uuid,
        new_title: newTitle,
        user: user.personalisation.id,
      };
      let res = await axios.post(
        `https://api.heygyani.com/library/update_notes_title/`,
        payload
      );
      if (res.data === "Title Updated") {
        fetchNotes();
        setchangeTitle(-1);
        setEditCatTitle(false);
        setEditTitle(newTitle);
      }
    }
    return;
  }

  const sortedData = (a, b) => {
    if (sortOrder === "asc") {
      return a.localeCompare(b);
    } else if (sortOrder === "des") {
      return b.localeCompare(a);
    } else {
      return;
    }
  };

  function find(note) {
    return (
      // note.title
      //   .toString()
      //   .toLowerCase()
      //   .includes(search.toString().toLowerCase()) ||
      note.toString().toLowerCase().includes(search.toString().toLowerCase())
      // note.notes
      //   .toString()
      //   .toLowerCase()
      //   .includes(search.toString().toLowerCase())
    );
  }

  function handleNewNotesClose() {
    if (newNotesTitle.length <= 3) {
      setshowError("Please provide Notes Name with minimum length 3!");
    } else {
      setshowError("");
      //Save New Notes
      setNewNotes(false);
    }
    fetchNotes();
  }
  // async function detectAI() {
  //   const div = document.getElementById("editorNotes");
  //   if (div !== null) {
  //     setLoading(true);

  //     let data = JSON.stringify({
  //       text: div.innerText,
  //     });
  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: "https://api.heygyani.com/api/inde_ai_detect/",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     const res = await axios.request(config);

  //     let real = 1 -res.data.fake;
  //     // console.log(real);
  //     setLoading(false);
  //     setScore(Math.floor(real*100));
  //   }
  // }

  // let interval = setInterval(() => {
  //   detectAI()
  // }, 10000);
  const sharedAlertLength = sharedalert.length > 0;
  const printNotes = () => {
    let printableElement = document.getElementById("editorjs");

    if (printableElement) {
      let redactorElement = printableElement.querySelector(
        ".codex-editor__redactor"
      );

      if (redactorElement) {
        let printContents = redactorElement.innerHTML;
        var myWindow = window.open("", "PrintNotes", "fullscreen=yes");

        if (myWindow) {
          myWindow.document.open();
          myWindow.document.write(
            "<html><head><title>PrintNotes</title></head><body>"
          );
          myWindow.document.write(printContents);
          myWindow.document.write("</body></html>");
          myWindow.document.close();

          myWindow.print();
          myWindow.close();
        } else {
          console.error("Failed to open the print window");
        }
      } else {
        console.error("Element with class 'codex-editor__redactor' not found");
      }
    } else {
      console.error("Element with ID 'printable' not found");
    }
  };

  const downloadNotes = async (notes, fileName, format) => {
    let printableElement = document.getElementById("printable");
    let printContents = null;
    const getTargetElement = () => document.getElementById('editorjs');

    //Hide Editor Tools 
    document.getElementById("printable").getElementsByClassName("ce-toolbar--opened")[0].style.display = 'none';
    if(document.getElementById("printable").getElementsByClassName("tc-add-row").length){
      document.getElementById("printable").getElementsByClassName("tc-add-row")[0].style.display = 'none';
    document.getElementById("printable").getElementsByClassName("tc-add-column")[0].style.display = 'none';
    }
    
    
    if (format === "pdf") {
      const options = {
        // default is `save`
        method: 'save',
        resolution: Resolution.LOW,
        page: {
           margin: RTPMargin.MEDIUM,
           // default is 'A4'
           format: 'letter',
           // default is 'portrait'
           orientation: 'portrait',
        },
        canvas: {
          mimeType: "image/png",
          qualityRatio: 2,
        },
        filename: fileName+'.pdf'
     };
      try {
        generatePDF(getTargetElement, options)
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    } else if (format === "docx") {
      // const template = []
      //i think ek template import krke then usme data input krna pdega and tab jaake hi i can create a docx file for that
      if (printableElement) {
        let redactorElement = printableElement.querySelector(
          ".codex-editor__redactor"
        );
  
        if (redactorElement) {
          printContents = redactorElement.innerText;
        }
      }
      const lines = printContents.split("\n");
      const content = lines.join("\n");
      // const zip = new JSZip(content);
      // const doc = new Docxtemplater(zip);
      try {
            const blob = new Blob(['\ufeff' + content], {
                type: 'application/msword'
            });

            // Create a download link
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'document.doc'; // Filename with .doc extension

            // Append link to the body (for Firefox)
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);

      } catch (error) {
        console.error("Error generating Docx file:", error);
      }
    } else {
      console.error("Unsupported format. Please specify 'pdf' or 'docx'.");
    }

    //Show Editor Tools Back 
    document.getElementById("printable").getElementsByClassName("ce-toolbar--opened")[0].style.display = 'block';
    if(document.getElementById("printable").getElementsByClassName("tc-add-row").length){
      document.getElementById("printable").getElementsByClassName("tc-add-row")[0].style.display = 'block';
    document.getElementById("printable").getElementsByClassName("tc-add-column")[0].style.display = 'block';
    }
  };

  useEffect(() => {
    let interval = null;
    if (isActive === true) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
        localStorage.setItem("notesTime", parseInt(time));
      }, 5);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time]);

  useEffect(() => {
    if (localStorage.getItem("notesTime") === "NaN") {
      localStorage.setItem("notesTime", 0);
    }
    setTimerStart(true);
    setIsActive(true);
    // setInterval(detectAI, 10000);
    setTimeout(() => {
      setStopType(true);
    }, 3000);
  }, []);

  const handleTimerStart = () => {
    console.log(localStorage.getItem("notesTime"));
    if (localStorage.getItem("notesTime") === "NaN") {
      localStorage.setItem("notesTime", 0);
    }
    setTimerStart(true);
    setIsActive(true);
    setTime(parseInt(localStorage.getItem("notesTime")));
  };

  const handleTimerStop = () => {
    localStorage.setItem("notesTime", 0);
    setTime(0);
    setTimerStart(false);
    setIsActive(false);
  };

  const saveText = useCallback(async () => {
    setSyncing(true);
    setNotesName(
      user.personalisation.id +
        "." +
        category +
        "." +
        (editRequest === "PUT" ? editTitle : newNotesTitle)
    );

    if (editRequest === "POST" && newNotesTitle.length <= 3) {
      setOpen(true);
      setSnackbarText("Title Must be more than 3 characters !");
      setSyncing(false);
      return;
    }

    let payload = {
      user: user.personalisation.id,
      title: editRequest === "PUT" ? editTitle : newNotesTitle,
      category: category === undefined ? "Uncategorised" : category,
      notes: JSON.stringify(text),
    };

    let res = null;

    editRequest === "PUT"
      ? (res = await axios.put(
          "https://api.heygyani.com/library/notes/",
          payload
        ))
      : (res = await axios.post(
          "https://api.heygyani.com/library/notes/",
          payload
        ));

    if (res.status === 200) {
      setOpen(res.data);
      setSnackbarText(res.data);
    }

    if (res.status === 200) {
      setSavedNotes(text);
      setSyncing(false);
      fetchNotes();
      return true;
    }
  }, [
    user.personalisation.id,
    category,
    editRequest,
    editTitle,
    newNotesTitle,
    text,
    setOpen,
    setSnackbarText,
    setSavedNotes,
    setSyncing,
    fetchNotes,
  ]);
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      //saveText();
    }, 20000);

    return () => {
      saveText()
      clearInterval(autoSaveInterval);
    }
  },[]);

  const handleEditNotes = (index, notes, title, cat, uuid, request) => {
    setExpand1(index);
    setEditNotes(notes);
    setEditTitle(title);
    setEditRequest(request);
    setEditView(false);
    setCategory(cat);
    setNotesId(index);
    setNotesuuid(uuid);
  };
  const handleCreateNewNote = async () => {
    setnew1("false");
    setplusd("false");
    try {
      await createNewCategoryWithNotes(
        document.getElementById("newNotesTitleName").value
      );
      try {
        const res = await axios.get(
          `https://api.heygyani.com/library/notes/${user.personalisation.id}`
        );
        const pH = Object.keys(res.data);
        pH.forEach((cat) => {
          const titles = res.data[cat]?.map((item) => item.title) || [];
          titleObject[cat] = titles;
        });
        const titlesArray = titleObject[newCategory ? newCategory : category];
        // console.log(titlesArray);
        if (titlesArray !== undefined) {
          const index = titlesArray.indexOf(
            document.getElementById("newNotesTitleName").value
          );
          const id = res.data[newCategory ? newCategory : category][index].id;
          const uuid =
            res.data[newCategory ? newCategory : category][index].uuid;
          handleEditNotes(
            id,
            notes,
            document.getElementById("newNotesTitleName").value,
            newCategory ? newCategory : category,
            uuid,
            "PUT"
          );
          toggleNotes(!viewNotes);
          setpaged(localStorage.getItem("first_time_user"));
          handleCategoryTitle(
            newCategory ? newCategory : category,
            document.getElementById("newNotesTitleName").value
          );
        } else {
          alert("index not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } catch (error) {
      console.error("Error creating new category with notes:", error);
    }
  };

  function convertStoMs(seconds) {
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = seconds % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    let res = minutes + " : " + extraSeconds;
    return res;
  }

  async function createNewCategoryWithNotes(title) {
    // setNotesName(
    //   user.personalisation.id + "." + newCategory + "." + "untitled"
    // );
    let payload = {
      user: user.personalisation.id,
      title: title,
      category: catSwitch
        ? newCategory
        : category === undefined
          ? "Uncategorised"
          : category,
      notes: "",
    };

    let res = null;

    if (payload.title.length <= 3) {
      setOpen(true);
      setSnackbarText("Title must be of more than 3 characters!");
      return;
    }
    if (payload.category.length <= 0) {
      setOpen(true);
      setSnackbarText("Category field is empty!");
      return;
    }

    res = await axios.post(
      "https://api.heygyani.com/library/notes/",
      payload
    );
    if (res.status === 200) {
      setOpen(res.data);
      setSnackbarText(res.data);
    }

    if (res.status === 200) {
      setSavedNotes(text);
    }
    fetchNotes();
    setCategoryDialog(false);
    return true;
  }

  async function shareNotes() {
    try {
      let data = JSON.stringify({
        note_uuid: shareDialog[0],
        shared_with: accessMail,
        access: access,
      });

      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: "https://api.heygyani.com/share_note/",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios.request(config);
      console.log(response.data);
      if (response.data.status === "success") {
        setSharedalert(`Shared with ${access} access`);
        setShareURL(response.data.shareable_link);
      } else {
        console.error("Sharing failed:", response.data.status);
      }
    } catch (error) {
      console.error("API request failed:", error.message);
    }
  }

  return (
    <div
      className="w-full !scrollbar-hide lg:p-2 !overflow-none  items-center">
      {editView && (
        <div className="flex w-full lg:flex-row flex-col justify-start lg:justify-between lg:items-center  lg:px-0 !overflow-none scrollbar-hide">
          {editView && (
            <div
              className="bg-gray-5 border-2 border-gray-200 focus-within:bg-white 
              items-center justify-between flex flex-row text-[#8C8CA1] w-[95%] ml-2 lg:ml-0 lg:mr-2
              focus-within:outline-[#428CFB] focus-within:outline-[3px] outline-none  font-[500]
              rounded-[8px] h-[48px] lg:w-full focus-within:shadow-xl focus-within:border-[#428CFB]
              focus-within:border-[1px solid] text-[#8C8CA1] mt-10 lg:mt-0 shadow-lg"
            >
              <input
                className="bg-gray-50 focus-within:bg-white w-full outline-none py-2 pl-2 rounded-lg "
                type="text"
                placeholder="Search Notes by Category"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Search className="bg-inherit" />
            </div>
          )}
          <div className="lg:flex lg:flex-row mt-5 lg:m-auto flex   justify-center gap-5 lg:grid-cols-3">
            {editView && (
              <>
                <div className="lg:col-span-1">
                  {!isMobileView && (
                    <Tooltip title={grid ? "List" : "Grid"} arrow>
                      <button
                        onClick={() => setGrid(!grid)}
                        className={`px-4 py-2 rounded-lg m-auto ${
                          grid ? "flex" : "lg:flex"
                        } flex-row justify-between items-center bg-gray-50 border-2 border-gray-200 text-black`}
                      >
                        {grid ? <ListAlt /> : <Grid4x4Outlined />}
                      </button>
                    </Tooltip>
                  )}
                </div>
                <div className="lg:col-span-1">
                  {!viewNotes ? (
                    <>
                      {plusd === "true" ? (
                        <Tooltip
                          open={plusd === "true"}
                          placement="bottom"
                          arrow
                          title={
                            <div className=" rounded-lg">
                              <p
                                className="m-2 text-white text-base  max-[750px]:text-xs"
                                style={{ fontFamily: "Work Sans" }}
                              >
                                Let's create your first note! 😎
                              </p>
                            </div>
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#0E0E2C",
                                "& .MuiTooltip-arrow": {
                                  color: "#0E0E2C",
                                },
                              },
                            },
                          }}
                        >
                          <button
                            onClick={() => {
                              setplusd("false");
                              setCategoryDialog(true);
                              setnew1("true");
                            }}
                            className="pulse px-4 py-2 rounded-lg m-auto flex flex-row justify-between items-center bg-blue-500 border-2 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-black "
                          >
                            <Add sx={{ color: grey[50] }} />
                          </button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Create New Note">
                          <button
                            onClick={() => setCategoryDialog(true)}
                            className="px-4 py-2 rounded-lg m-auto flex flex-row justify-between items-center bg-blue-500 border-2 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-black "
                          >
                            <Add sx={{ color: grey[50] }} />
                          </button>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setEditRequest("POST");
                        setEditNotes(null);
                        setExpand1(-1);
                        toggleNotes(!viewNotes);
                        setEditView(true);
                      }}
                      className="px-4 py-2 rounded-lg flex flex-row justify-between items-center bg-gray-50 border-2 border-gray-200 text-black "
                    >
                      <span className="hidden lg:block">All Notes</span>{" "}
                      <Notes />
                    </button>
                  )}
                </div>
                <div className="lg:col-span-1">
                  {editView && (
                    <select
                      className="px-4 py-0 my-0  h-[41.6167px] rounded-lg bg-gray-50 border-2 border-gray-200 text-black !outline-none focus:text-inherit"
                      value={sortOrder}
                      onChange={(e) => setsortOrder(e.target.value)}
                    >
                      <option className="!" value={"nosort"}>
                        No Sort
                      </option>
                      <option className="!" value={"asc"}>
                        Ascending
                      </option>
                      <option className="!" value={"des"}>
                        Descending
                      </option>
                    </select>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className="scrollbar-hide h-full m-auto">
        {viewNotes ? (
          <div className="!lg:w-full overflow-y-none rounded-2xl py-2 scrollbar-hide">
            <div className="flex flex-col lg:flex-row justify-between items-center">
              <div className="fixed top-0 flex w-full px-5 flex-col lg:flex-row lg:w-full justify-between items-center !overflow-none gap-2 bg-white z-50">
                <div className="my-auto flex justify-center items-center gap-2">
                <button
                    className="flex justify-start items-center rounded-full px-[0.5px] hover:bg-[#EFEFFD]"
                    onClick={() => {
                      setEditRequest("POST");
                      setEditNotes(null);
                      setExpand1(-1);
                      toggleNotes(!viewNotes);
                      setEditView(true);
                    }}
                  >
                    <Tooltip arrow title="All Notes">
                      <IconButton
                        size="medium"
                        type="button"
                        className={` hover:opacity-[0.5]`}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  </button>
                  {editRequest === "POST" ? (
                    <input
                      className="pl-3  w-full lg:w-1/2 hover:bg-[#EFEFFD]  outline-none focus:outline-[3px] py-3 focus:outline-blue-500 rounded-lg"
                      value={newNotesTitle}
                      onChange={(e) => setNewNotesTitle(e.target.value)}
                      placeholder="Notes Name"
                    />
                  ) : editCatTitle ? (
                    <div className="flex justify-between items-center flex-row gap-2">
                      <input
                        placeholder={editTitle}
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        onKeyUp={(e) =>
                          e.code === "Enter" ? updateTitle(e) : null
                        }
                        className="outline-none rounded-lg px-2"
                        onBlur={(e) => updateTitle(e)}
                      />
                      <div className="flex justify-between items-center flex-row  gap-2">
                        <div onClick={() => updateTitle(editTitle, newTitle)}>
                          <Done />
                        </div>
                        <div onClick={() => setEditCatTitle(!editCatTitle)}>
                          <Close />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span className="lg:text-lg font-bold text-left uppercase m-auto lg:w-full ">
                      {category}/
                      <span
                        onDoubleClick={() => setEditCatTitle(!editCatTitle)}
                      >
                        {editTitle}
                      </span>
                    </span>
                  )}
                </div>
                <div className="ml-24 mr-auto hidden lg:flex p-2  flex-col lg:flex-row lg:justify-start gap-4  items-center scrollbar-hide w-fit">
                  <button
                    className="flex justify-start items-center rounded-full px-[0.5px] hover:bg-[#EFEFFD]"
                    onClick={() => {
                      saveText();
                      setsav("false");
                      localStorage.setItem("first_time_user", "false");
                    }}
                  >
                    <span className="hidden px-4">SAVE</span>
                    <>
                      {sav === "true" ? (
                        <Tooltip
                          open={sav === "true"}
                          placement="bottom"
                          arrow
                          title={
                            <div className=" rounded-lg">
                              <p
                                className=" text-white text-base max-[750px]:text-xs"
                                style={{ fontFamily: "Work Sans" }}
                              >
                                Woohoo! You've just made your debut note!
                              </p>
                              <p
                                className=" text-white text-base text-center max-[750px]:text-xs"
                                style={{ fontFamily: "Work Sans" }}
                              >
                                🙌
                              </p>
                              <p
                                className=" text-white text-base max-[750px]:text-xs"
                                style={{ fontFamily: "Work Sans" }}
                              >
                                Make sure to save it so you don't lose it!
                              </p>
                            </div>
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#0E0E2C",
                                "& .MuiTooltip-arrow": {
                                  color: "#0E0E2C",
                                },
                              },
                            },
                          }}
                        >
                          <IconButton
                            size="medium"
                            type="button"
                            className={`pulse hover:opacity-[0.5] ${
                              syncing && "animate-spin"
                            }`}
                          >
                            <Save />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip arrow title="Save">
                          <IconButton
                            size="medium"
                            type="button"
                            className={` hover:opacity-[0.5] ${
                              syncing && "animate-spin"
                            }`}
                          >
                            <Save />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  </button>
                  <button className="flex justify-start items-center rounded-full px-[0.5px] gap-4 hover:bg-[#EFEFFD]">
                    <span className="hidden px-4">SHARE</span>
                    <>
                      {sar === "true" ? (
                        <Tooltip
                          open={sar === "true"}
                          placement="bottom"
                          arrow
                          title={
                            <div className=" rounded-lg">
                              <p
                                className="text-white  text-base"
                                style={{ fontFamily: "Work Sans" }}
                              >
                                Awesome! You’re almost finished with your note
                                journey!{" "}
                              </p>
                              <p
                                className="text-white text-base mt-2 max-[750px]:text-xs"
                                style={{ fontFamily: "Work Sans" }}
                              >
                                Why not share this with someone? Don't worry,
                                you can manage who can edit or view it! You can
                                even publish it within the Gyani community!
                                After all, sharing is a form of caring! 😍
                              </p>
                            </div>
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#0E0E2C",
                                "& .MuiTooltip-arrow": {
                                  color: "#0E0E2C",
                                },
                              },
                            },
                          }}
                        >
                          <IconButton
                            aria-label="share"
                            className="pulse px-[0.5px] !hover:bg-[#EFEFFD]"
                            onClick={() => {
                              console.log("clicked");
                              shareDialog.push(Notesuuid);
                              setsar("false");
                              setsav("true");
                            }}
                          >
                            <Share />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip arrow title="Share">
                          <IconButton
                            aria-label="share"
                            className="px-[0.5px] !hover:bg-[#EFEFFD]"
                            onClick={() => {
                              console.log("clicked");
                              shareDialog.push(Notesuuid);
                            }}
                          >
                            <Share />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  </button>
                  {/* <Tooltip title="Print" arrow>
                    <button
                      className="flex justify-start items-center gap-4  rounded-full px-[0.5px] hover:bg-[#EFEFFD]"
                      onClick={() => printNotes()}
                    >
                      <IconButton
                        size="large"
                        className="px-[0.5px] !hover:bg-[#EFEFFD]"
                      >
                        <Print />
                      </IconButton>
                    </button>
                  </Tooltip> */}
                  <div>
                    <Tooltip title="Download" arrow>
                      <IconButton
                        onClick={DhandleOpenMenu}
                        size="large"
                        className="px-[0.5px] !hover:bg-[#EFEFFD]"
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={DanchorEl}
                      open={Boolean(DanchorEl)}
                      onClose={DhandleCloseMenu}
                    >
                      <MenuItem
                        onClick={() => downloadNotes(notes, editTitle, "pdf")}
                      >
                        Download as PDF
                      </MenuItem>
                      <MenuItem
                        onClick={() => downloadNotes(notes, editTitle, "docx")}
                      >
                        Download as DOCX
                      </MenuItem>
                    </Menu>
                  </div>
                  <button
                    className="flex justify-start items-center rounded-full px-[0.5px] hover:bg-[#EFEFFD]"
                    onClick={() => {
                      setimportalert(true);
                    }}
                  >
                    <Tooltip arrow title="Import Files">
                      <IconButton
                        size="medium"
                        type="button"
                        className={` hover:opacity-[0.5]`}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </button>
                  <>
                    {timerd === "true" ? (
                      <Tooltip
                        open={timerd === "true"}
                        placement="bottom"
                        arrow
                        title={
                          <div className=" rounded-lg">
                            <p
                              className="m-2 text-white text-base max-[750px]:text-xs"
                              style={{ fontFamily: "Work Sans" }}
                            >
                              Let's start the timer! Then you can keep track of
                              your writing time. ⏱️
                            </p>
                          </div>
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#0E0E2C",
                              "& .MuiTooltip-arrow": {
                                color: "#0E0E2C",
                              },
                            },
                          },
                        }}
                      >
                        <button className="pulse flex justify-start items-center  rounded-full px-[0.5px] hover:bg-[#EFEFFD]">
                          {timerStart ? (
                            <IconButton
                              size="large"
                              className="px-[0.5px] !border-solid !border-[1px] !border-red-500 !hover:bg-[#EFEFFD] animate-pulse "
                              onClick={() => handleTimerStop()}
                            >
                              <Stop />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="large"
                              className="px-[0.5px] !hover:bg-[#EFEFFD]"
                              onClick={() => {
                                handleTimerStart();
                                settimerd("false");
                                setcompletiond("true");
                              }}
                            >
                              <Timer />
                            </IconButton>
                          )}
                          {"   "}
                        </button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={timerStart ? "Stop" : "Start"}
                        arrow
                        // followCursor
                      >
                        <button className="flex justify-start items-center  rounded-full px-[0.5px] hover:bg-[#EFEFFD]">
                          {timerStart ? (
                            <IconButton
                              size="small"
                              className="px-[0.5px] !border-solid !border-[1px] !border-red-500 !hover:bg-[#EFEFFD]  "
                              onClick={() => handleTimerStop()}
                            >
                              <Stop />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="large"
                              className="px-[0.5px] !hover:bg-[#EFEFFD]"
                              onClick={() => {
                                handleTimerStart();
                              }}
                            >
                              <Timer />
                            </IconButton>
                          )}
                        </button>
                      </Tooltip>
                    )}
                  </>
                  <span className="flex text-[0.835rem]">
                    {timerStart && convertStoMs(parseInt(time / 1000))}
                  </span>
                </div>
                <div className="m-auto lg:fixed lg:right-16">
                  {gs === "true" ? (
                    <Tooltip
                      open={gs === "true"}
                      placement="bottom"
                      arrow
                      title={
                        <div className=" rounded-lg">
                          <p
                            className=" text-white text-base max-[750px]:text-xs"
                            style={{ fontFamily: "Work Sans" }}
                          >
                            Click here to see the magic! Gyani AI lets you do
                            all the writing work! ✍️
                          </p>
                        </div>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#0E0E2C",
                            "& .MuiTooltip-arrow": {
                              color: "#0E0E2C",
                            },
                          },
                        },
                      }}
                    >
                      <button
                        onClick={() => {
                          setSideMenu(true);
                          setgs("false");
                        }}
                        style={{
                          backgroundColor: "#428CFB",
                          color: "white",
                        }}
                        className="pulse z-50 border border-transparent rounded-lg  flex flex-row justify-center items-center space-x-1 py-2  "
                      >
                        <span
                          className="font-bold px-3"
                          style={{ fontFamily: "Work Sans" }}
                        >
                          Gyani AI
                        </span>
                      </button>
                    </Tooltip>
                  ) : (
                    <button
                      onClick={() => {
                        setSideMenu(!sideMenu);
                      }}
                      style={{
                        backgroundColor: "#428CFB",
                        color: "white",
                      }}
                      className="right-0 border z-51 border-transparent rounded-lg h-8 m-auto  flex flex-row justify-center items-center space-x-1 py-2  "
                    >
                      <span
                        className="text-[1.2em] font-bold px-3"
                        style={{ fontFamily: "Work Sans" }}
                      >
                        Gyani AI
                      </span>
                    </button>
                  )}
                </div>
                {/* Mobie View Left Button */}
                <div onClick={()=>props.setOpen(true)} className="fixed top-1 left-1 p-1 text-black cursor-pointer font-bold"><img src={LeftMenuButtonSVG} height={30} width={30}/></div>

              </div>
              <br />
            </div>
            <br />
            <div className="w-full h-full scrollbar-hide mt-2 ">
              {expand1 < 0 ? (
                <NotesMaker
                  sendRefToParent={receiveRefFromChild}
                  id="printable"
                  LeftDrawerOpen={props.open}
                  notes={null}
                  setText={setText}
                  text={text}
                  newNotesTitle={newNotesTitle}
                  editTitle={editTitle}
                  category={category}
                  setNotesName={setNotesName}
                  setSavedNotes={setSavedNotes}
                  fetchNotes={fetchNotes}
                  request="POST"
                  sideMenu={sideMenu}
                  timer={parseInt(time / 1000)}
                  setSideMenu={setSideMenu}
                  re2={re2}
                  setre2={setre2}
                  sar={sar}
                  setsar={setsar}
                  gs={gs}
                  setgs={setgs}
                  re={re}
                  setre={setre}
                  parad={parad}
                  setparad={setparad}
                  dri={dri}
                  setdri={setdri}
                  handleTimerStart={handleTimerStart}
                  handleTimerStop={handleTimerStop}
                  timerStart={timerStart}
                  convertStoMs={convertStoMs}
                  time={time}
                  timerd={timerd}
                  settimerd={settimerd}
                  setcompletiond={setcompletiond}
                  completiond={completiond}
                  Notesuuid={Notesuuid}
                  shareDialog={shareDialog}
                  sav={sav}
                  setsav={setsav}
                />
              ) : (
                <NotesMaker
                  sendRefToParent={receiveRefFromChild}
                  id="printable"
                  LeftDrawerOpen={props.open}
                  setSnackbarText={setSnackbarText}
                  snackbarText={snackbarText}
                  caption={caption}
                  headCaption={headCaption}
                  titleObject={titleObject}
                  notes={notes}
                  setText={setText}
                  text={text}
                  newNotesTitle={newNotesTitle}
                  editTitle={editTitle}
                  category={category}
                  setNotesName={setNotesName}
                  setSavedNotes={setSavedNotes}
                  fetchNotes={fetchNotes}
                  request="PUT"
                  sideMenu={sideMenu}
                  setSideMenu={setSideMenu}
                  re2={re2}
                  setre2={setre2}
                  notesId={notesId}
                  sar={sar}
                  setsar={setsar}
                  gs={gs}
                  setgs={setgs}
                  re={re}
                  setre={setre}
                  parad={parad}
                  setparad={setparad}
                  dri={dri}
                  setdri={setdri}
                  handleTimerStart={handleTimerStart}
                  handleTimerStop={handleTimerStop}
                  timerStart={timerStart}
                  convertStoMs={convertStoMs}
                  time={time}
                  timerd={timerd}
                  settimerd={settimerd}
                  setcompletiond={setcompletiond}
                  completiond={completiond}
                  Notesuuid={Notesuuid}
                  shareDialog={shareDialog}
                  sav={sav}
                  setsav={setsav}
                />
              )}
            </div>
          </div>
        ) : notes ? (
          <DragDropContext
            onDragEnd={handleOnDragEnd}
            className="!scrollbar-hide overflow-y-none w-full"
          >
            <Droppable droppableId="cat">
              {(provided) => (
                <ul
                  className={` ${
                    grid
                      ? "grid gap-2 grid-cols-3"
                      : "flex lg:flex-row flex-col flex-wrap justify-evenly items-start"
                  } characters !overflow-none mt-10  w-full  `}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {Object.keys(notes)
                    .filter(find)
                    .sort(sortedData)
                    .map((cat, i) => (
                      <Draggable key={cat} draggableId={cat} index={i}>
                        {(provided) => (
                          <li
                            onClick={() => {
                              setCatOpen((prev) =>
                                prev === i ? -1 : i
                              );
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`!scrollbar-hide !p-0 lg:w-${
                              grid ? "" : "full"
                            }`}
                          >
                            <div className="lg:w-full w-[93.5%] lg:p-0 pl-3 ">
                              <Card className={`!p-0 `}>
                                <CardHeader
                                  avatar={
                                    <Avatar
                                      sx={{
                                        bgcolor: "#2563eb",
                                        opacity: "0.5",
                                      }}
                                      aria-label="recipe"
                                    >
                                      <img
                                        alt="Error"
                                        src="https://icons.veryicon.com/png/o/business/a-set-of-commercial-icons/notes-54.png"
                                      ></img>
                                    </Avatar>
                                  }
                                  title={cat}
                                  subheader={
                                    notes[cat][0]?.date_created
                                      .substring(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("-") || null
                                  }
                                  // action={
                                  //   <IconButton
                                  //     style={{
                                  //       padding: "2px",
                                  //       backgroundColor:
                                  //         catOpen === i
                                  //           ? "#EFEFFD"
                                  //           : "transparent",
                                  //     }}
                                     
                                  //   >
                                  //     <ExpandMore
                                  //     onClick={() => {
                                  //       setCatOpen((prev) =>
                                  //         prev === i ? -1 : i
                                  //       );
                                  //     }}
                                  //       aria-expanded={catOpen === i}
                                  //       aria-label="show more"
                                  //     ></ExpandMore>
                                  //   </IconButton>
                                  // }
                                />
                                <CardActions
                                  className="flex justify-between"
                                  disableSpacing
                                ></CardActions>
                                <Collapse in={catOpen === i}>
                                  <CardContent
                                    className={` ${
                                      grid
                                        ? "!flex !flex-col !rounded-full"
                                        : ""
                                    }`}
                                  >
                                    {Object.values(notes[cat] || []).map(
                                      (
                                        { title, category, id, notes, uuid },
                                        key
                                      ) => (
                                        <div
                                          className={`flex lg:w-full flex-row justify-evenly items-center text-center text-gray-800   h-[50px]  rounded-lg bg-gradient-to-r from-[#EFEFFD] border-2 border-gray-200 my-4 `}
                                          key={key}
                                        >
                                          {changeTitle === id ? (
                                            <div className="flex justify-between items-center flex-row gap-2 !w-full">
                                              <input
                                                placeholder={title}
                                                value={newTitle}
                                                onChange={(e) =>
                                                  setNewTitle(e.target.value)
                                                }
                                                onKeyUp={(e) =>
                                                  e.code === "Enter"
                                                    ? updateTitle(e)
                                                    : null
                                                }
                                                className="outline-none rounded-lg px-2 text-center !w-[100%]"
                                                onBlur={(e) => updateTitle(e)}
                                              />
                                              <div className="flex justify-between items-center flex-row gap-2">
                                                <div
                                                  onClick={() =>
                                                    updateTitle(id, newTitle)
                                                  }
                                                >
                                                  <Done />
                                                </div>
                                                <div
                                                  onClick={() =>
                                                    setchangeTitle(-1)
                                                  }
                                                >
                                                  <Close />
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <span
                                              onDoubleClick={() =>
                                                setchangeTitle(id)
                                              }
                                              className="w-[200px]"
                                            >
                                              {!stopType ? (
                                                <Typewriter
                                                  options={{
                                                    strings: title,
                                                    autoStart: true,
                                                    delay: 500,
                                                  }}
                                                />
                                              ) : (
                                                title
                                              )}
                                            </span>
                                          )}
                                          <span className="h-full pt-5 w-1/2 rounded-lg"></span>
                                          <Button
                                            className="flex flex-row justify-center items-center !"
                                            onClick={() => {
                                              handleEditNotes(
                                                id,
                                                notes,
                                                title,
                                                category,
                                                uuid,
                                                "PUT"
                                              );
                                              toggleNotes(!viewNotes);
                                              setpaged(
                                                localStorage.getItem(
                                                  "first_time_user"
                                                )
                                              );
                                              handleCategoryTitle(
                                                newCategory
                                                  ? newCategory
                                                  : category,
                                                title
                                              );
                                            }}
                                          >
                                            <Tooltip title="Edit" arrow>
                                              <div className="flex justify-end items-end w-100 ml-2">
                                                <Edit />
                                              </div>
                                            </Tooltip>
                                          </Button>
                                          <IconButton
                                            aria-label="share"
                                            className="px-[0.5px] !hover:bg-[#EFEFFD]"
                                            onClick={() => {
                                              console.log("clicked");
                                              shareDialog.push(uuid);
                                            }}
                                          >
                                            <Share />
                                          </IconButton>
                                          <IconButton
                                            onClick={() => handleDelete(id)}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </div>
                                      )
                                    )}
                                  </CardContent>
                                </Collapse>
                              </Card>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <p className="w-full bg-blue-400 h-20">Loading</p>
        )}
      </div>
      <Dialog
        className="bg-white/50"
        open={importalert}
        keepMounted
        onClose={() => setimportalert(false)}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          className: "lg:w-1/2",
        }}
      >
        <DialogActions className="!flex !justify-between">
          <DialogTitle className="font-bold font-700 !p-4 !pb-0 !justify-start">
            IMPORT FILES
          </DialogTitle>
          <Button onClick={() => setimportalert(false)}>
            <Clear />
          </Button>
        </DialogActions>
        <DialogContent className="!p-0">
          <div className="!m-3 !flex !flex-col ">
            <div>
              <span className="uppercase font-bold">From Local Storage:</span>
              <br></br>
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    setLocalfile(e.target.files[0]);
                  }
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  uploadLocalFileEditor();
                }}
              >
                Upload
              </Button>
            </div>
            <div>
              <span className="uppercase font-bold">From Google Drive:</span>
              <br></br>
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    setGooglefile(e.target.files[0]);
                  }
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  uploadGoogleFileEditor(googlefile);
                }}
              >
                Upload
              </Button>
            </div>
          </div>
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={() => setShareDialog(false)}>Submit</Button>
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={categoryDialog}
        keepMounted
        onClose={() => {
          setnew1("false");
          setCategoryDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          className: "lg:w-1/2",
        }}
      >
        <DialogActions>
          <Button
            onClick={() => {
              setnew1("false");
              setCategoryDialog(false);
            }}
          >
            <Clear />
          </Button>
        </DialogActions>
        <DialogTitle className="font-bold">Create a new Note</DialogTitle>
        <DialogContent>
          New Category
          <Switch
            checked={catSwitch}
            onChange={() => setCatSwitch(!catSwitch)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <DialogContentText id="alert-dialog-slide-description">
            {catSwitch ? (
              <>
                <input
                  type="text"
                  placeholder="Category Name"
                  onChange={(e) => setNewCategory(e.target.value)}
                  rows={1}
                  className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3 scrollbar-hide"
                />
                <div className="my-5" />
                <input
                  id="newNotesTitleName"
                  type="text"
                  placeholder="Note's Name"
                  rows={1}
                  className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3 scrollbar-hide"
                />
              </>
            ) : (
              <div className="w-full">
                Select Category <br />
                <Select
                  placeholder="Select Category"
                  defaultValue={"Uncategorised"}
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  fullWidth
                >
                  {Object.keys(notes).map((cat, i) => (
                    <MenuItem value={cat} key={i}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
                <div className="my-5" />
                <input
                  id="newNotesTitleName"
                  type="text"
                  placeholder="Note's Name"
                  rows={1}
                  className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3 scrollbar-hide"
                />
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip
            open={new1 === "true"}
            placement="bottom"
            arrow
            title={
              <div className=" rounded-lg">
                <p
                  className="m-2 text-white text-base max-[750px]:text-xs "
                  style={{ fontFamily: "Work Sans" }}
                >
                  Got a bunch of projects? Create a whole category for them.
                  This lets you organize your projects. Come up with a cool name
                  for it! 😉
                </p>
              </div>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#0E0E2C",
                  "& .MuiTooltip-arrow": {
                    color: "#0E0E2C",
                  },
                },
              },
            }}
          >
            <Button variant="contained" onClick={handleCreateNewNote}>
              Create
            </Button>
          </Tooltip>
          <Button onClick={() => setCategoryDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={paged === "true"}
        keepMounted
        onClose={() => {
          setpaged("false");
        }}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            backgroundColor: "black",
            borderRadius: 10,
          },
        }}
      >
        <DialogContent className="  rounded-lg ">
          <p
            className="text-white text-base  max-[750px]:text-xs"
            style={{ fontFamily: "Work Sans" }}
          >
            Kudos! Your first note has been successfully created! 🎉{" "}
          </p>
          {/* <ArrowBackIcon sx= {
                      {
                       color: 'white',
                     }
                   } className="ml-16 cursor-pointer" fontSize="large" onClick={()=>{
          }}/> */}
          <ArrowForwardIcon
            sx={{
              color: "white",
            }}
            className="ml-2 cursor-pointer"
            fontSize="large"
            onClick={() => {
              settimerd("true");
              handleTimerStop();
              setpaged("false");
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={completiond === "true"}
        keepMounted
        onClose={() => {
          setcompletiond("false");
        }}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            backgroundColor: "black",
            borderRadius: 10,
          },
        }}
      >
        <DialogContent className="  rounded-lg">
          <p
            className="text-white text-base  max-[750px]:text-xs"
            style={{ fontFamily: "Work Sans" }}
          >
            Excellent work! You're making great progress!🚀
          </p>
          {/* <ArrowBackIcon sx= {
                      {
                       color: 'white',
                     }
                   } className="ml-16 cursor-pointer" fontSize="large" onClick={()=>{
                    settimerd("true")
                    setcompletiond("false")
          }}/> */}
          <ArrowForwardIcon
            sx={{
              color: "white",
            }}
            className="ml-2 cursor-pointer"
            fontSize="large"
            onClick={() => {
              setcompletiond("false");
              setre("true");
            }}
          />
        </DialogContent>
      </Dialog>
      {/* Share Notes Drawer  */}
      <Dialog
        open={shareDialog && shareDialog.length > 0}
        keepMounted
        onClose={() => setShareDialog([])}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={
          {
            // className: "lg:w-",s
          }
        }
      >
        <DialogActions>
          <Button onClick={() => setShareDialog([])}>
            <Clear />
          </Button>
        </DialogActions>
        <DialogTitle className="font-bold">Share Notes</DialogTitle>
        <DialogContent className="flex mt-2 pt-2 justify-around">
          <TextField
            placeholder="Enter Mail"
            onChange={(e) => setAccessMail(e.target.value)}
            id="outlined-basic"
            label="Mail"
            variant="filled"
          />
          <Select onChange={(e) => setAccess(e.target.value)} value={access}>
            <MenuItem value="edit">Editor</MenuItem>
            <MenuItem value="view">Viewer</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions className="justify-around">
          <Button variant="contained" onClick={() => shareNotes()}>
            Share
          </Button>
          <Button variant="contained">Share To Community</Button>
          <Button variant="outlined" onClick={() => setShareDialog([])}>
            Cancel
          </Button>
        </DialogActions>
        <p className="m-auto justify-center">URL for sharing</p>
        <DialogActions className="m-auto justify-center ">
          {" "}
          <a
            href={shareURL}
            target="_blank"
            rel="noopener noreferrer"
            className="small-link overflow-hidden max-w-80"
          >
            {shareURL}
          </a>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={snackbarText}
        anchorOrigin={{ vertical, horizontal }}
      >
        {snackbarText === "Title must be of more than 3 characters!" ||
        snackbarText === "Category field is empty!" ? (
          <Alert
            severity={"error"}
            sx={{
              backgroundColor: "#dc2f02",
            }}
          >
            {snackbarText}
          </Alert>
        ) : (
          <Alert
            severity={"success"}
            sx={{
              backgroundColor: "lightGreen",
            }}
          >
            {snackbarText}
          </Alert>
        )}
      </Snackbar>
      {/* Shared Notes Alert  */}

      <Snackbar
        open={sharedAlertLength}
        autoHideDuration={3000}
        onClose={() => setSharedalert("")}
        message={sharedalert}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          severity={"success"}
          sx={{
            backgroundColor: "lightGreen",
          }}
        >
          {sharedalert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MyNotes;
