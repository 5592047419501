import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Bookmark from "../SummaryGenerator/components/Bookmark";
import Loading from "../books_landing/Loading";

const Bookmark1 = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const handleView = (url) => {
    window.open(url, "_blank");
  };

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        "https://api.heygyani.com/get-bookmarks/",
        {
          userid: user.id,
        }
      );
      console.log(response.data)
      setBookmarks(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
      setLoading(false);
    }
  }, [user.id]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  const handleDeleteBookmark = async (id) => {
    try {
      await axios.delete("https://api.heygyani.com/delete_paper_bookmarks/", {
        data: {
          userid: user.id,
          bookmark_id: id,
        },
      });

      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark.id !== id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="m-auto items-center w-full">
      <div className="text-3xl font-bold my-5 text-center text-blue-500">
        Bookmarks
      </div>
      {loading && <Loading />}
      {bookmarks?.length >= 1 &&
        bookmarks.map(
          (bookmark) =>
            bookmark && (
              <div key={bookmark.paperId}>
                <Bookmark
                  setLoading={setLoading}
                  data={bookmark}
                  onViewInNewTab={() => handleView(bookmark.paper_url)}
                  onDeleteBookmark={() => handleDeleteBookmark(bookmark.id)}
                  fetchData={fetchData}
                />
              </div>
            )
        )}
    </div>
  );
};

export default Bookmark1;
