import type {
  API,
  InlineTool,
  InlineToolConstructorOptions,
} from "@editorjs/editorjs";

interface TemplateCiteToolConfig {
  buttonHTML?: string;
  html: string;
  setGenerateInput: any;
  setResearchDialog: any;
  setGlowUp: any;
  setGenerateDialog: any;
  setCiteMenu: any;
  handleCiteMenuPosition:any;
}

interface TemplateInlineToolConstructorOptions
  extends InlineToolConstructorOptions {
  config?: TemplateCiteToolConfig;
}

class InlineCiteButton implements InlineTool {
  static isSurroundEnabled: boolean = true;

  static get isInline() {
    return true;
  }

  static get title() {
    return "Cite";
  }

  #api: API;
  #config!: TemplateCiteToolConfig;

  constructor({ api, config }: TemplateInlineToolConstructorOptions) {
    this.#api = api;
    if (config && "html" in config) {
      this.#config = config;
    }
  }

  get shortcut() {
    return "CMD+R";
  }

  checkState() {
    return false;
  }

  render() {
    const button = document.createElement("button");

    button.classList.add(this.#api.styles.inlineToolButton);
    button.type = "button";

    button.innerHTML =
      this.#config.buttonHTML ??
      `
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
            <path d="M336 64h32a48 48 0 0148 48v320a48 48 0 01-48 48H144a48 48 0 01-48-48V112a48 48 0 0148-48h32" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
            <rect x="176" y="32" width="100" height="64" rx="26.13" ry="26.13" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
          </svg>
        `;

    button.onclick = (e) => {
      this.#config.setGenerateInput(document.getSelection()?.toString());
      this.#config.setResearchDialog(true);
      this.#config.setGlowUp(true);
      this.#config.setGenerateDialog(true);
      this.#config.handleCiteMenuPosition(e);
    };

    return button;
  }

  surround() {
    if (!InlineCiteButton.isSurroundEnabled) {
      return;
    }

    // document.execCommand("insertHTML", false, this.#config.html);

    InlineCiteButton.isSurroundEnabled = false;

    setTimeout(() => (InlineCiteButton.isSurroundEnabled = true));
  }
}

export { InlineCiteButton };
export type { TemplateCiteToolConfig };
