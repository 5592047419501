import React, { useState } from "react";
import { MenuItem, Select } from "@mui/material";

const Bookmark = (props) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const [cite, setSite] = useState("IEEE");

  return (
    <div className="flex justify-center">
      <div className="max-w-[600px] mx-auto my-5">
        <div className="w-full max-h-[400px] overflow-hidden border-2 p-4 rounded-lg shadow-md bg-white transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={cite}
              onChange={(e) => setSite(e.target.value)}
              label="Age"
              className="h-[28px]"
            >
              <MenuItem value={"IEEE"}>IEEE</MenuItem>
              <MenuItem value={"Chicago"}>Chicago</MenuItem>
              <MenuItem value={"Harvard"}>Harvard</MenuItem>
              <MenuItem value={"MLA"}>MLA</MenuItem>
              <MenuItem value={"APA"}>APA</MenuItem>
            </Select>

            <button
              className="h-[28px] flex flex-row justify-start items-center px-2 gap-1 rounded-[6px] hover:bg-blue-700 text-[12px] font-[600] leading-[14px] text-[#ffffff] bg-blue-500 outline-none focus:outline-4 focus:outline-[#31D0AA]"
              onClick={() => {
                props.onDeleteBookmark();
                props.fetchData();
              }}
            >
              Delete
            </button>
          </div>

          {/* Title */}
          <div
            className="font-bold text-md mb-2"
            style={{
              fontFamily: "",
              fontSize: "1rem",
              lineHeight: "1.5",
            }}
          >
            {props.data.paper_title}
          </div>

          {/* Authors */}
          <div
            className="text-gray-600 mb-1 overflow-hidden overflow-ellipsis line-clamp-1 italic"
            style={{ fontSize: "0.9rem" }}
          ></div>
          <div className="mb-2"></div>

          <div className="flex justify-between items-center">
            <div className="flex gap-2 mt-2">
              <button
                className="border-[1px] border-blue-500 rounded-2xl py-2 px-2 text-black text-[12px] font-[600] leading-[14px]"
                onClick={props.onViewInNewTab}
              >
                View in a new Tab
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookmark;
