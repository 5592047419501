import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/AuthContext";
import backdrop from "./login.png";
import axios from "axios";
import "./Signup.css";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import logo from "../../SVGAvatars/fullLogo.svg";

// // import Pdf from "react-pdf";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login_3() {
  const [open_err, setOpenErr] = React.useState(false);
  const [open_suc, setopen_suc] = useState(false);

  // const { state } = useLocation();
  // const { email } = state;
  let { user, setUser } = useContext(UserContext);

  let navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErr(false);
  };
  const handleClose_success = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    open_suc(false);
  };

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  const handleSubmit = async (e) => {
    //Prevent page reload
    e.preventDefault();

    var { email, password } = document.forms[0];
    try {
      let res = await axios.post("https://api.heygyani.com/login/", {
        email: email.value,
        password: password.value,
      });
      console.log(res);
      localStorage.setItem("token", res.data[1]["access"]);
      localStorage.setItem("user", JSON.stringify(res.data[0]));
      localStorage.setItem("first_time_user", res.data[2]);
      setopen_suc(true);
      setUser(res.data);

      window.location = "/dashboard/home";
    } catch (error) {
      setOpenErr(true);
      console.error(error);
    }
  };

  const handleClick = () => {
    let path = `/signup`;
    navigate(path);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="grid grid-cols-2">
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="absolute">
          <img
            className="lg:w-[8rem] lg:h-[3.5rem] w-[8rem] h-[3rem] fixed p-2 top-0 left-0"
            alt="Error"
            src={logo}
          />
        </div>
        <h2 className="font-[Work Sans] text-[40px] font-[700] leading-[47px] tracking-[-0.02em] mb-2">
          Enter your password to get started!
        </h2>
        <h3 className="font-[Work Sans] text-[24px] font-[500] leading-[28px] text-[#0E0E2C] mb-2">
          Just a few more steps and you're finished!
        </h3>
        <h3 className="font-[Work Sans] text-[24px] font-[500] leading-[28px] text-[#0E0E2C] mb-8">
          We hate paperwork, too.
        </h3>
        <form
          className="flex flex-col items-center"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col items-center w-[300px] lg:w-[659px]">
            <input
              type="email"
              name="email"
              placeholder="EMAIL ADDRESS"
              className={` h-8 px-2 mt-4 outline-none rounded-md bg-gray-200 shadow-md ${
                open_err ? "border-red-400" : "border-blue-400"
              } focus:border-blue-400 focus:outline-none`}
            />
            <input
              type="password"
              name="password"
              placeholder="PASSWORD"
              className={` h-8 px-2 mt-4 outline-none rounded-md bg-gray-200 shadow-md ${
                open_err ? "border-red-400" : "border-blue-400"
              } focus:border-blue-400 focus:outline-none`}
            />
          </div>
          <div className="items-center">
            <span
              onClick={() => navigate("/forget")}
              className="text-green-500 cursor-pointer mt-2 text-xs"
            >
              Forgot Password
            </span>
          </div>
          {open_err && !open_suc && (
            <input
              type="text"
              name="status"
              placeholder="Status"
              disabled
              className="w-[300px] lg:w-[659px] h-12 mt-4 px-4 text-red-500 text-center outline-none rounded-md bg-gray-200 shadow-md focus:outline-none"
              value="Something Went Wrong"
            />
          )}
          {open_suc && (
            <input
              type="text"
              name="status"
              placeholder="Status"
              disabled
              className="w-[300px] lg:w-[659px] h-12 mt-4 px-4 text-green-500 text-center outline-none rounded-md bg-gray-200 shadow-md focus:outline-none"
              value="Login Successful"
            />
          )}
          <div className="mt-3">
            <span
              onClick={() => navigate("/signup")}
              className="text-green-500 cursor-pointer text-sm"
            >
              New User? Sign up
            </span>
          </div>
          <div className=" mt-4 m-auto justify-center items-center ">
            <button
              className="p-1 h-8 rounded-md bg-blue-500 hover:bg-blue-400 text-white font-semibold focus:outline-none focus:border-blue-400"
              type="submit"
              onClick={handleSubmit}
            >
              LOGIN
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-col bg-gray-200">
        <div className="max-h-screen m-auto">
          <img
            className="lg:w-[12rem] lg:h-[4rem] w-[8rem] h-[3rem] mb-4"
            alt="Error"
            src={logo}
          />
        </div>
        <img className="justify-bottom" src={backdrop} alt="Backdrop" />
      </div>
    </div>
  );
}

export default Login_3;
